import React, { useState, useContext } from "react";

import CartManager from "../../store/cart-manager";

import Button from "../UI/Button/Button";

import styles from "./MenuList.module.css";

export default function MenuList(props) {
  const ctx = useContext(CartManager);
  const [amount, setAmount] = useState(1);

  const amountHanlder = (event) => {
    if (
      event.target.value < 1 ||
      event.target.value > 5 ||
      event.target.type !== "number"
    )
      return;
    setAmount(event.target.value);
  };

  const addItemHandler = (title, price) => {
    ctx.addToCart({
      title,
      price,
      amount: parseInt(amount),
    });
  };

  return (
    <li key={props.title}>
      <div className={styles.liLeftSide}>
        <h3>{props.title}</h3>
        <p>
          <i>{props.des}</i>
        </p>
        <p>${props.price}</p>
      </div>
      <div className={styles.liRightSide}>
        <div>
          <h4>Amount</h4>
          <input
            value={amount}
            type="number"
            min="1"
            max="5"
            step="1"
            onChange={amountHanlder}
          />
        </div>
        <Button onClick={() => addItemHandler(props.title, props.price)}>
          + Add
        </Button>
      </div>
    </li>
  );
}
