import styles from "./Button.module.css";

export default function Button(props) {
  return (
    <button
      className={`${props.className} ${styles.btn} ${props.white ? styles.btnWhite : ""}`}
      type={props.type ? props.type : "button"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
