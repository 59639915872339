import React, { useContext, useState } from "react";
import useOnLoad from "../Hooks/useOnLoad";
import CartManager from "../../store/cart-manager";

import UserCartList from "./UserCartList";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import Checkout from "../Checkout/Checkout";

import styles from "./UserCartModal.module.css";

const UserCart = () => {
  const [isCheckout, setIsCheckout] = useState(false);
  const ctx = useContext(CartManager);
  let totalPrice = 0;

  const closeCartHandler = () => {
    ctx.closeCart();
  };

  const openCheckoutHandler = () => {
    if (ctx.itemsTotal === 0) return;
    setIsCheckout(true);
  };

  const closeCheckoutHandler = () => {
    setIsCheckout(false);
  };

  const moveLeft = isCheckout ? styles.moveLeft : "";

  return (
    <Modal
      className={`${styles.userCart} ${useOnLoad() && styles.cartAppears}`}
      onClick={closeCartHandler}
    >
      <div className={`${styles.useCart__div} ${moveLeft}`}>
        {ctx.itemsTotal > 0 && (
          <ul>
            {/* this iterates over the object items from cart-manager */}
            {Object.keys(ctx.items).map((title) => {
              const item = ctx.items[title];
              if (item.amount > 0) {
                totalPrice = totalPrice + item.price * item.amount;
                return (
                  <UserCartList
                    key={title}
                    title={title}
                    price={item.price}
                    amount={item.amount}
                  />
                );
              }
              return <React.Fragment key={title}></React.Fragment>;
            })}
          </ul>
        )}
        {ctx.itemsTotal === 0 && (
          <p className={styles.empty}>Your cart is empty</p>
        )}

        <div className={styles.total}>
          <h3>Total Amount: </h3>
          <h3>${totalPrice.toFixed(2)}</h3>
        </div>
        <div className={styles.controls}>
          <Button onClick={closeCartHandler} white={true}>
            Close
          </Button>
          <Button onClick={openCheckoutHandler} disabled={ctx.itemsTotal === 0}>
            Order
          </Button>
        </div>
      </div>
      <div className={`${styles.useCart__div} ${moveLeft}`}>
        <Checkout onCloseOrder={closeCheckoutHandler} />
      </div>
    </Modal>
  );
};

export default UserCart;
