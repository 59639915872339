import React, { useContext } from "react";

import CartManager from "./store/cart-manager";

import styles from "./App.module.css";

import Header from "./Components/Layout/Header";
import Menu from "./Components/Menu/Menu";
import UserCartModal from "./Components/UserCart/UserCartModal";

function App() {
  const ctx = useContext(CartManager);
  return (
    <React.Fragment>
      <Header />
      {ctx.cartIsShown && <UserCartModal />}
      <main className={styles.main}>
        <Menu />
      </main>
    </React.Fragment>
  );
}

export default App;
