import styles from "./Successful.module.css";

import useOnLoad from "../../Hooks/useOnLoad";
import Button from "../Button/Button";
import { useContext } from "react";
import CartManager from "../../../store/cart-manager";

export default function Successful() {
  const ctx = useContext(CartManager);

  return (
    <div
      className={`${styles.container} ${!useOnLoad() && styles.successAppear}`}
    >
      <p className={styles.success}>Thank you for your order</p>{" "}
      <Button onClick={ctx.closeCart}>Close</Button>
    </div>
  );
}
