import { useCallback, useState } from "react";

export default function useHttp() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const sendRequest = useCallback(async (requestConfig) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "GET",
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      if (!response.ok) throw new Error("Something went wrong!");

      const data = await response.json();
      setData(data);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, []);

  return {
    success,
    loading,
    error,
    data,
    sendRequest,
  };
}
