import styles from "./LoadingCircle.module.css";
import useOnLoad from "../../Hooks/useOnLoad";

export default function LoadingCircle() {
  return (
    <div
      className={`${styles.loading} ${useOnLoad() && styles.loadingAppears}`}
    >
      <div className={styles.spinner}></div>
    </div>
  );
}
