import ErrorText from "../UI/Error/ErrorText";
import styles from "./CheckoutInput.module.css";

export default function CheckoutInput(props) {
  return (
    <div className={`${props.className} ${styles.control}`}>
      <div className={props.error ? styles.error : ""}>
        <label htmlFor={props.id}>{props.label}</label>
        <input id={props.id} type={props.type} onChange={props.onChange} />
      </div>
      {props.error && <ErrorText>{props.errorMsg}</ErrorText>}
    </div>
  );
}
