import React, { useEffect, useState } from "react";
import useOnLoad from "../Hooks/useOnLoad.js";
import useHttp from "../../hooks/use-http.js";

import Card from "../UI/Card/Card";

import styles from "./Menu.module.css";

import Welcome from "./Welcome/Welcome.js";
import MenuList from "./MenuList";
import LoadingCircle from "../UI/Loading/LoadingCircle.js";
import ErrorText from "../UI/Error/ErrorText.js";

export default function Menu() {
  const [itemsDB, setItemsDB] = useState([]);

  const { loading, error, data, sendRequest: fetchData } = useHttp();

  useEffect(() => {
    fetchData({
      url: "https://reacting-http-default-rtdb.europe-west1.firebasedatabase.app/Carts.json",
    });
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      const items = Object.keys(data).map((item) => {
        return {
          id: item,
          title: data[item].title,
          des: data[item].des,
          price: data[item].price,
        };
      });
      setItemsDB(items);
    }
  }, [data]);

  const menuList = itemsDB.map((item) => {
    return (
      <MenuList
        key={item.id}
        title={item.title}
        des={item.des}
        price={item.price}
      />
    );
  });

  return (
    <React.Fragment>
      <Welcome />
      <Card className={`${styles.menu} ${useOnLoad() && styles.menuAppears}`}>
        <ul>{menuList}</ul>
        {loading && <LoadingCircle />}
        {error && <ErrorText>{error}</ErrorText>}
      </Card>
    </React.Fragment>
  );
}
