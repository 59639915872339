import React, { useState, useContext, useEffect } from "react";

import CheckoutInput from "./CheckoutInput";
import Button from "../UI/Button/Button";

import styles from "./Checkout.module.css";
import CartManager from "../../store/cart-manager";
import useHttp from "../../hooks/use-http";
import ErrorText from "../UI/Error/ErrorText";
import LoadingCircle from "../UI/Loading/LoadingCircle";
import Successful from "../UI/Successful/Successful";

// NOTE: this is for simplifying validity
const isEmpty = (value) => value.trim() === "";
const isFiveChar = (value) => /^\d{5}$/.test(value);

export default function Checkout(props) {
  const ctx = useContext(CartManager);

  const [formInputs, setFormInputs] = useState({
    name: "",
    street: "",
    postal: "",
    city: "",
  });
  const [formInputsValidity, setFormInputsValidity] = useState({
    name: true,
    street: true,
    postal: true,
    city: true,
  });
  const nameOnChange = (event) => {
    setFormInputsValidity((prevState) => {
      return {
        ...prevState,
        name: true,
      };
    });
    setFormInputs((prevState) => {
      return { ...prevState, name: event.target.value };
    });
  };
  const streetOnChange = (event) => {
    setFormInputsValidity((prevState) => {
      return {
        ...prevState,
        street: true,
      };
    });
    setFormInputs((prevState) => {
      return { ...prevState, street: event.target.value };
    });
  };
  const postalOnChange = (event) => {
    setFormInputsValidity((prevState) => {
      return {
        ...prevState,
        postal: true,
      };
    });

    setFormInputs((prevState) => {
      return { ...prevState, postal: event.target.value };
    });
  };
  const cityOnChange = (event) => {
    setFormInputsValidity((prevState) => {
      return {
        ...prevState,
        city: true,
      };
    });
    setFormInputs((prevState) => {
      return { ...prevState, city: event.target.value };
    });
  };

  const {
    success: didSubmit,
    loading: isSubmitting,
    error,
    sendRequest: sendCartData,
  } = useHttp();

  const submissionHandler = async (event) => {
    event.preventDefault();

    const nameIsEmpty = !isEmpty(formInputs.name);
    const streetIsEmpty = !isEmpty(formInputs.street);
    const postalIsEmpty = isFiveChar(formInputs.postal);
    const cityIsEmpty = !isEmpty(formInputs.city);

    setFormInputsValidity({
      name: nameIsEmpty,
      street: streetIsEmpty,
      postal: postalIsEmpty,
      city: cityIsEmpty,
    });

    const formIsValid =
      nameIsEmpty && streetIsEmpty && postalIsEmpty && cityIsEmpty;

    if (!formIsValid) return;

    let items = {};
    for (let key in ctx.items)
      if (ctx.items[key].amount !== 0) items[key] = ctx.items[key];

    if (Object.keys(items).length === 0) return;

    items = { meals: { ...items }, formInputs };
    sendCartData({
      url: "https://reacting-http-default-rtdb.europe-west1.firebasedatabase.app/checkout.json",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: items,
    });
  };

  useEffect(() => {
    if (didSubmit) ctx.resetItems();
  }, [ctx, didSubmit]);

  const formModal = (
    <form onSubmit={submissionHandler} className={styles.form}>
      <CheckoutInput
        type="text"
        id="name"
        label="Your Name"
        onChange={nameOnChange}
        errorMsg="Please enter a valid name."
        error={!formInputsValidity.name}
      />
      <CheckoutInput
        type="text"
        id="street"
        label="Street"
        onChange={streetOnChange}
        errorMsg="Please enter a valid street."
        error={!formInputsValidity.street}
      />
      <CheckoutInput
        type="text"
        id="postal"
        label="Postal Code"
        onChange={postalOnChange}
        errorMsg="Please enter a valid postal code (5 digits)."
        error={!formInputsValidity.postal}
      />
      <CheckoutInput
        type="text"
        id="city"
        label="City"
        onChange={cityOnChange}
        errorMsg="Please enter a valid city."
        error={!formInputsValidity.city}
      />
      <div className={styles.controls}>
        <div>
          <Button onClick={props.onCloseOrder}>Go back</Button>
          <Button type="submit">Conform</Button>
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    </form>
  );

  return (
    <React.Fragment>
      {!isSubmitting && !didSubmit && formModal}
      {isSubmitting && <LoadingCircle />}
      {!isSubmitting && didSubmit && <Successful />}
    </React.Fragment>
  );
}
