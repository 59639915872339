import React, { useContext } from "react";
import styles from "./UserCartList.module.css";
import CartManager from "../../store/cart-manager";

export default function UserCartList(props) {
  const ctx = useContext(CartManager);

  return (
    <li className={styles.item}>
      <div className={styles.item__Div_Title}>
        <h3>{props.title}</h3>
        <div className={styles.item__Div__Div__Price_Amount}>
          <p>${props.price}</p>
          <p>x {props.amount}</p>
        </div>
      </div>
      <div className={styles.controls}>
        <button onClick={() => ctx.cartAmount("-", props)}>-</button>
        <button onClick={() => ctx.cartAmount("+", props)}>+</button>
      </div>
    </li>
  );
}
