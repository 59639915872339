import { useState, useEffect } from "react";

export default function useOnLoad() {
  const [onLoad, setOnLoad] = useState(true);

  useEffect(() => {
    setOnLoad(false);
  }, [onLoad]);

  return onLoad;
}
