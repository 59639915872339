import React, { useContext, useEffect, useRef, useState } from "react";

import CartManager from "../../store/cart-manager";

import styles from "./HeaderCartButton.module.css";
import CartIcon from "../UI/Icons/CartIcon";

export default function HeaderCartButton() {
  const ctx = useContext(CartManager);
  const btnRef = useRef();

  const [initiate, setInitiate] = useState(false);
  const { itemsTotal } = ctx;

  useEffect(() => {
    if (itemsTotal === 0) return;

    setInitiate(true);

    const timer = setTimeout(() => {
      setInitiate(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [itemsTotal]);

  return (
    <button
      ref={btnRef}
      className={`${styles.CartButton} ${initiate && styles.beat}`}
      onClick={() => ctx.openCart()}
    >
      <CartIcon /> Your Cart{" "}
      <span className={styles.badge}>{ctx.itemsTotal}</span>
    </button>
  );
}
