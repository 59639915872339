import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import Card from "../Card/Card";
import useOnLoad from "../../Hooks/useOnLoad";

const ModalBackDrop = (props) => {
  return (
    <div
      className={`${styles.backdrop} ${useOnLoad() && styles.modalAppear}`}
      onClick={props.onClick}
    ></div>
  );
};

const ModalOverLay = (props) => {
  return (
    <div>
      <Card className={`${props.className} `}>{props.children}</Card>
    </div>
  );
};

export default function Modal(props) {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ModalBackDrop onClick={props.onClick} />,
        document.getElementById("BackDrop"),
      )}
      {ReactDOM.createPortal(
        <ModalOverLay className={props.className}>
          {props.children}
        </ModalOverLay>,
        document.getElementById("OverLay"),
      )}
    </React.Fragment>
  );
}
