import React from "react";
import HeaderCartButton from "./HeaderCartButton.js";

import styles from "./Header.module.css";

export default function Header() {
  return (
    <React.Fragment>
      <nav className={styles.navigation}>
        <div className={styles.navigation__div}>
          <h2>ReactMeals</h2>
          <HeaderCartButton />
        </div>
      </nav>
      <div className={styles["main-table"]}>
        <img
          src={
            "https://raw.githubusercontent.com/academind/react-complete-guide-code/refs/heads/11-practice-food-order-app/extra-files/meals.jpg"
          }
          alt="a table with various types of food"
        />
      </div>
    </React.Fragment>
  );
}
