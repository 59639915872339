import React, { useReducer, useState } from "react";

// CartManagerContext
const CartManager = React.createContext({
  items: {},
  itemsTotal: 0,
  resetItems: () => {},
  itemsDB: [],
  addToCart: () => {},

  cartIsShown: false,
  closeCart: () => {},
  openCart: () => {},
});
// CartManagerContext

// Provider
// NOTE: This is responsible for altering the data that came from the database
const INITIAL_OBJECT = {};
const itemsReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    state[`${action.value.title}`].amount += action.value.amount;
    return state;
  } else if (action.type === "INCREASE_BY_ONE") {
    state[`${action.value.title}`].amount += 1;
    return state;
  } else if (action.type === "DECREASE_BY_ONE") {
    state[`${action.value.title}`].amount -= 1;
    return state;
  } else if (action.type === "RESET") {
    return INITIAL_OBJECT;
  }
  return INITIAL_OBJECT;
};

const fetchData = async () => {
  const response = await fetch(
    "https://reacting-http-default-rtdb.europe-west1.firebasedatabase.app/Carts.json",
  );
  const data = await response.json();
  if (data) {
    for (let key in data) {
      const item = data[key];
      INITIAL_OBJECT[item.title] = {
        price: item.price,
        amount: 0,
      };
    }
  }
};
fetchData();
// NOTE: end

export function CartManagerProvider(props) {
  const [itemsState, itemsDispatch] = useReducer(itemsReducer, INITIAL_OBJECT);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [cartIsShown, setCartIsShown] = useState(false);

  const AddToCartHandler = (item) => {
    itemsDispatch({ type: "ADD_TO_CART", value: item });
    setItemsTotal((prevState) => prevState + item.amount);
  };

  const CartAmountHanlder = (type, item) => {
    if (type === "+") {
      itemsDispatch({ type: "INCREASE_BY_ONE", value: item });
      setItemsTotal((prevState) => prevState + 1);
    } else {
      itemsDispatch({ type: "DECREASE_BY_ONE", value: item });
      setItemsTotal((prevState) => prevState - 1);
    }
  };

  return (
    <CartManager.Provider
      value={{
        items: itemsState,
        itemsTotal: itemsTotal >= 999 ? "999+" : itemsTotal,
        resetItems: () => {
          itemsDispatch({ type: "RESET" });
          setItemsTotal(0);
        },
        addToCart: (item) => {
          AddToCartHandler(item);
        },

        cartIsShown: cartIsShown,
        closeCart: () => {
          setCartIsShown(false);
        },
        openCart: () => {
          setCartIsShown(true);
        },

        cartAmount: (type, item) => {
          CartAmountHanlder(type, item);
        },
      }}
    >
      {props.children}
    </CartManager.Provider>
  );
}
// Provider

export default CartManager;
