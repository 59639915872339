import React from "react";

import Card from "../../UI/Card/Card";
import styles from "./Welcome.module.css";

export default function Welcome() {
  return (
    <Card className={styles.container}>
      <h1>Delicious Food, Delivered To You </h1>
      <p>
        Choose your favorite from our broad selection of available meals and
        enjoy a delicious lunch or dinner at home
      </p>
      <p>
        All our meals are cooked with high-quality ingrediants, just-in-time and
        of course by experienced chefs!
      </p>
    </Card>
  );
}
